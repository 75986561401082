import { CountryCallingCode, getCountries, getCountryCallingCode } from "libphonenumber-js";
import { useEffect, useState } from "react";
import useCountryOptions from "./useCountryOptions";

export default function useCountryCodeOptions() {
  const [countries, setCountries] = useState<{ displayLabel: string; label: string; value: string; country: string }[]>(
    []
  );
  const countryOptions = useCountryOptions();
  useEffect(() => {
    const countriesList = getCountries();
    const countriesWithCodes = countriesList.flatMap((country) => {
      const countryOption = countryOptions.find((option) => option.value === country);
      const countryLabel = countryOption ? countryOption.label : country;
      let countryCallingCode = getCountryCallingCode(country);

      const customCountryCodes: { [key: string]: string } = {
        AX: "358-18",
        AS: "1-684",
        AI: "1-264",
        AG: "1-268",
        BS: "1-242",
        BB: "1-246",
        BM: "1-441",
        VG: "1-284",
        KY: "1-345",
        DM: "1-767",
        DO: "1-809",
        GD: "1-473",
        GU: "1-671",
        JM: "1-876",
        MS: "1-664",
        MP: "1-670",
        PR: "1-787",
        KN: "1-869",
        LC: "1-758",
        VC: "1-784",
        TT: "1-868",
        TC: "1-649",
        VI: "1-340",
      };

      if (customCountryCodes[country]) {
        countryCallingCode = customCountryCodes[country] as CountryCallingCode;
      }

      if (["VA", "EH", "SH", "IO"].includes(country)) {
        return [];
      }

      if (["247", "383", "690"].includes(countryCallingCode)) {
        return [];
      }

      return {
        displayLabel: `${countryLabel} (+${countryCallingCode})`,
        label: countryCallingCode,
        value: countryCallingCode,
        country: country,
      };
    });

    const hkIndex = countriesWithCodes.findIndex((item) => item.country === "HK");
    const hkItem = countriesWithCodes[hkIndex];
    if (hkIndex > -1) {
      countriesWithCodes.splice(hkIndex, 1);
      countriesWithCodes.unshift(hkItem);
    }

    const sortedCountries = countriesWithCodes.slice(1).sort((a, b) => a.displayLabel.localeCompare(b.displayLabel));
    sortedCountries.unshift(hkItem);
    setCountries(sortedCountries);
  }, [countryOptions]);

  return countries;
}
