import { useTransContent } from "modules/common/trans-content/transContent";
import { useMemo } from "react";

export default function useCountryOptions() {
  const { t } = useTransContent();
  return useMemo(
    () => [
      {
        label: t("web.flightBooking.travelDetail.country.options.HongKongSARChina") as string,
        value: "HK",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Afghanistan") as string,
        value: "AF",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.ÅlandIslands") as string,
        value: "AX",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Albania") as string,
        value: "AL",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Algeria") as string,
        value: "DZ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.AmericanSamoa") as string,
        value: "AS",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Andorra") as string,
        value: "AD",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Angola") as string,
        value: "AO",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Anguilla") as string,
        value: "AI",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.AntiguaBarbuda") as string,
        value: "AG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Argentina") as string,
        value: "AR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Armenia") as string,
        value: "AM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Aruba") as string,
        value: "AW",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Australia") as string,
        value: "AU",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Austria") as string,
        value: "AT",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Azerbaijan") as string,
        value: "AZ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Bahamas") as string,
        value: "BS",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Bahrain") as string,
        value: "BH",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Bangladesh") as string,
        value: "BD",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Barbados") as string,
        value: "BB",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Belarus") as string,
        value: "BY",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Belgium") as string,
        value: "BE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Belize") as string,
        value: "BZ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Benin") as string,
        value: "BJ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Bermuda") as string,
        value: "BM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Bhutan") as string,
        value: "BT",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Bolivia") as string,
        value: "BO",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.BosniaHerzegovina") as string,
        value: "BA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Botswana") as string,
        value: "BW",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Brazil") as string,
        value: "BR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.BritishVirginIslands") as string,
        value: "VG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Brunei") as string,
        value: "BN",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Bulgaria") as string,
        value: "BG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.BurkinaFaso") as string,
        value: "BF",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Burundi") as string,
        value: "BI",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Cambodia") as string,
        value: "KH",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Cameroon") as string,
        value: "CM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Canada") as string,
        value: "CA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.CapeVerde") as string,
        value: "CV",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.CaymanIslands") as string,
        value: "KY",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.CentralAfricanRepublic") as string,
        value: "CF",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Chad") as string,
        value: "TD",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Chile") as string,
        value: "CL",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.China") as string,
        value: "CN",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.ChristmasIsland") as string,
        value: "CX",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.CocosKeelingIslands") as string,
        value: "CC",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Colombia") as string,
        value: "CO",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Comoros") as string,
        value: "KM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.CongoBrazzaville") as string,
        value: "CG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.CongoKinshasa") as string,
        value: "CD",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.CookIslands") as string,
        value: "CK",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.CostaRica") as string,
        value: "CR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.CôtedIvoire") as string,
        value: "CI",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Croatia") as string,
        value: "HR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Cuba") as string,
        value: "CU",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Curaçao") as string,
        value: "CW",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Cyprus") as string,
        value: "CY",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.CzechRepublic") as string,
        value: "CZ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Denmark") as string,
        value: "DK",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Djibouti") as string,
        value: "DJ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Dominica") as string,
        value: "DM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.DominicanRepublic") as string,
        value: "DO",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Ecuador") as string,
        value: "EC",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Egypt") as string,
        value: "EG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.ElSalvador") as string,
        value: "SV",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.EquatorialGuinea") as string,
        value: "GQ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Eritrea") as string,
        value: "ER",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Estonia") as string,
        value: "EE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Ethiopia") as string,
        value: "ET",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.FalklandIslands") as string,
        value: "FK",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.FaroeIslands") as string,
        value: "FO",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Fiji") as string,
        value: "FJ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Finland") as string,
        value: "FI",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.France") as string,
        value: "FR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.FrenchGuiana") as string,
        value: "GF",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.FrenchPolynesia") as string,
        value: "PF",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Gabon") as string,
        value: "GA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Gambia") as string,
        value: "GM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Georgia") as string,
        value: "GE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Germany") as string,
        value: "DE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Ghana") as string,
        value: "GH",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Gibraltar") as string,
        value: "GI",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Greece") as string,
        value: "GR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Greenland") as string,
        value: "GL",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Grenada") as string,
        value: "GD",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Guadeloupe") as string,
        value: "GP",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Guam") as string,
        value: "GU",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Guatemala") as string,
        value: "GT",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Guernsey") as string,
        value: "GG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Guinea") as string,
        value: "GN",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.GuineaBissau") as string,
        value: "GW",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Guyana") as string,
        value: "GY",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Haiti") as string,
        value: "HT",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Honduras") as string,
        value: "HN",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Hungary") as string,
        value: "HU",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Iceland") as string,
        value: "IS",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.India") as string,
        value: "IN",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Indonesia") as string,
        value: "ID",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Iran") as string,
        value: "IR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Iraq") as string,
        value: "IQ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Ireland") as string,
        value: "IE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.IsleofMan") as string,
        value: "IM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Israel") as string,
        value: "IL",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Italy") as string,
        value: "IT",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Jamaica") as string,
        value: "JM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Japan") as string,
        value: "JP",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Jersey") as string,
        value: "JE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Jordan") as string,
        value: "JO",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Kazakhstan") as string,
        value: "KZ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Kenya") as string,
        value: "KE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Kiribati") as string,
        value: "KI",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Kuwait") as string,
        value: "KW",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.KyrgyzRepublic") as string,
        value: "KG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Laos") as string,
        value: "LA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Latvia") as string,
        value: "LV",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Lebanon") as string,
        value: "LB",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Lesotho") as string,
        value: "LS",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Liberia") as string,
        value: "LR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Libya") as string,
        value: "LY",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Liechtenstein") as string,
        value: "LI",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Lithuania") as string,
        value: "LT",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Luxembourg") as string,
        value: "LU",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.MacauSARChina") as string,
        value: "MO",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Macedonia") as string,
        value: "MK",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Madagascar") as string,
        value: "MG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Malawi") as string,
        value: "MW",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Malaysia") as string,
        value: "MY",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Maldives") as string,
        value: "MV",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Mali") as string,
        value: "ML",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Malta") as string,
        value: "MT",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.MarshallIslands") as string,
        value: "MH",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Martinique") as string,
        value: "MQ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Mauritania") as string,
        value: "MR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Mauritius") as string,
        value: "MU",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Mayotte") as string,
        value: "YT",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Mexico") as string,
        value: "MX",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Micronesia") as string,
        value: "FM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Moldova") as string,
        value: "MD",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Monaco") as string,
        value: "MC",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Mongolia") as string,
        value: "MN",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Montenegro") as string,
        value: "ME",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Montserrat") as string,
        value: "MS",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Morocco") as string,
        value: "MA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Mozambique") as string,
        value: "MZ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.MyanmarBurma") as string,
        value: "MM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Namibia") as string,
        value: "NA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Nauru") as string,
        value: "NR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Nepal") as string,
        value: "NP",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Netherlands") as string,
        value: "NL",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.NewCaledonia") as string,
        value: "NC",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.NewZealand") as string,
        value: "NZ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Nicaragua") as string,
        value: "NI",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Niger") as string,
        value: "NE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Nigeria") as string,
        value: "NG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Niue") as string,
        value: "NU",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.NorfolkIsland") as string,
        value: "NF",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.NorthKorea") as string,
        value: "KP",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.NorthernMarianaIslands") as string,
        value: "MP",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Norway") as string,
        value: "NO",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Oman") as string,
        value: "OM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Other") as string,
        value: "OT",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Pakistan") as string,
        value: "PK",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Palau") as string,
        value: "PW",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.PalestinianTerritories") as string,
        value: "PS",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Panama") as string,
        value: "PA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.PapuaNewGuinea") as string,
        value: "PG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Paraguay") as string,
        value: "PY",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Peru") as string,
        value: "PE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Philippines") as string,
        value: "PH",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Poland") as string,
        value: "PL",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Portugal") as string,
        value: "PT",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.PuertoRico") as string,
        value: "PR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Qatar") as string,
        value: "QA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Réunion") as string,
        value: "RE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Romania") as string,
        value: "RO",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Russia") as string,
        value: "RU",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Rwanda") as string,
        value: "RW",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Samoa") as string,
        value: "WS",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.SanMarino") as string,
        value: "SM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.SãoToméPríncipe") as string,
        value: "ST",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.SaudiArabia") as string,
        value: "SA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Senegal") as string,
        value: "SN",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Serbia") as string,
        value: "RS",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Seychelles") as string,
        value: "SC",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.SierraLeone") as string,
        value: "SL",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Singapore") as string,
        value: "SG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.SintMaarten") as string,
        value: "SX",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Slovakia") as string,
        value: "SK",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Slovenia") as string,
        value: "SI",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.SolomonIslands") as string,
        value: "SB",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Somalia") as string,
        value: "SO",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.SouthAfrica") as string,
        value: "ZA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.SouthKorea") as string,
        value: "KR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.SouthSudan") as string,
        value: "SS",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Spain") as string,
        value: "ES",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.SriLanka") as string,
        value: "LK",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.StBarthélemy") as string,
        value: "BL",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.StHelena") as string,
        value: "TA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.StKittsNevis") as string,
        value: "KN",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.StLucia") as string,
        value: "LC",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.StMartin") as string,
        value: "MF",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.StPierreMiquelon") as string,
        value: "PM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.StVincentGrenadines") as string,
        value: "VC",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Sudan") as string,
        value: "SD",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Suriname") as string,
        value: "SR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.SvalbardJanMayen") as string,
        value: "SJ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Swaziland") as string,
        value: "SZ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Sweden") as string,
        value: "SE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Switzerland") as string,
        value: "CH",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Syria") as string,
        value: "SY",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.TaiwanChina") as string,
        value: "TW",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Tajikistan") as string,
        value: "TJ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Tanzania") as string,
        value: "TZ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Thailand") as string,
        value: "TH",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.TimorLeste") as string,
        value: "TL",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Togo") as string,
        value: "TG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Tonga") as string,
        value: "TO",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.TrinidadTobago") as string,
        value: "TT",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Tunisia") as string,
        value: "TN",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Turkey") as string,
        value: "TR",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Turkmenistan") as string,
        value: "TM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.TurksCaicosIslands") as string,
        value: "TC",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Tuvalu") as string,
        value: "TV",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.USOutlyingIslands") as string,
        value: "UM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.USVirginIslands") as string,
        value: "VI",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Uganda") as string,
        value: "UG",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Ukraine") as string,
        value: "UA",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.UnitedArabEmirates") as string,
        value: "AE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.UnitedKingdom") as string,
        value: "GB",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.UnitedStates") as string,
        value: "US",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Uruguay") as string,
        value: "UY",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Uzbekistan") as string,
        value: "UZ",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Vanuatu") as string,
        value: "VU",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Venezuela") as string,
        value: "VE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Vietnam") as string,
        value: "VN",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.WallisFutuna") as string,
        value: "WF",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Yemen") as string,
        value: "YE",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Zambia") as string,
        value: "ZM",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.Zimbabwe") as string,
        value: "ZW",
      },
      {
        label: t("web.flightBooking.travelDetail.country.options.CaribbeanNetherlands") as string,
        value: "BQ",
      },
    ],
    [t]
  );
}
